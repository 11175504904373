import { defineSetupVue3 } from "@histoire/plugin-vue"

import Oruga from "@oruga-ui/oruga-next"
import { bulmaConfig } from "@oruga-ui/theme-bulma"

import { useDark } from "@vueuse/core"
import generateString from "@/dragonforce/docs/utils/longStrings"

import "@fortawesome/fontawesome-free/css/all.css"
import "@/dragonforce/docs/histoire.scss"

export const setupVue3 = defineSetupVue3(({ app, story, variant }) => {
  app.use(Oruga, bulmaConfig)

  app.mixin({
    methods: { generateString },
  })

  const isDark = useDark({
    selector: "html",
    valueDark: "theme-dark",
    valueLight: "theme-light",
  })
})
